<template>
  <div style="display: inline">
    <viewer
      hidden
      :options="options"
      :images="images"
      @inited="inited"
      class="viewer"
      ref="viewer"
    >
      <template slot-scope="scope">
        <img v-for="src in scope.images" :src="src" :key="src" />
        {{ scope.options }}
      </template>
    </viewer>
    <v-btn v-bind="$attrs" @click="showImages()"><slot></slot></v-btn>
  </div>
</template>

<script>
export default {
  props: ['images'],
  data: () => ({
    dialog: false,
    options: {
      navbar: false,
      title: false,
      toolbar: false
    }
  }),
  methods: {
    showImages() {
      this.$viewer.show()
    },
    inited(viewer) {
      this.$viewer = viewer
    }
  }
}
</script>

<style></style>
